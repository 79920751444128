
import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";
import { ReportService, FileService, WechatService, UserService } from "src/services";
import dayjs from "dayjs";
import pageBack from "src/components/page-back";
import { downloadFile } from "src/utils/extends";
import { EnumUtils, tools, Pay } from "src/utils";
import sportPdf from "./sport-pdf.vue";
import globalConfig from "src/config/index";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component({
  components: {
    pageBack,
    sportPdf,
  },
})
export default class ReportDecodeList extends Vue {
  /**
   * 是否是ai跳转过来
   * @private
   * @returns boolean
   */
  private get isAi(): boolean {
    return this.$route.query && this.$route.query.isAi == "true" ? true : false;
  }

  /**
   * 是否显示加载中
   * @private
   *
   */
  private isLoading: Boolean = false;

  /**
   * 报告id
   * @protected
   * @returns Array<any>
   */
  protected reportId = null;

  /**
   * 全部指标
   * @protected
   * @returns Array<any>
   */
  protected activeNames = [];

  /**
   * 机构品牌
   * @protected
   * @returns Array<any>
   */
  protected ReportDetail = {};

  /**
   * 解析结果
   * @protected
   * @returns Array<any>
   */
  protected analysisResults = [];

  /**
   * 解析数量
   * @protected
   * @returns Array<any>
   */
  protected analysisNumber = null;

  /**
   * 重点关注指标结果
   * @protected
   * @returns Array<any>
   */
  protected keyIndicatorsList = [];

  /**
   * 重点关注指标数量
   * @protected
   * @returns Array<any>
   */
  protected keyIndicatorsNumber = null;

  /**
   * 组件进来之前
   * @private
   * @returns void
   */
  private beforeRouteEnter(to, from, next): void {
    if (to.query && to.query.isAi == "true") {
      next();
      return;
    } else {
      let appid = localStorage.getItem("appid");
      if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname) {
        location.replace(`/${appid}${to.fullPath}`);
        next();
      } else {
        next();
      }
    }
  }

  private onFixedChange(value) {
    this.activeNames = value;
  }

  /**
   * 跳转页面
   * @private
   * @returns void
   */
  private onGopage(name: string): void {
    this.$router.push({ name });
  }

  //获取解析的体检报告
  protected async queryAnalyze(reportId) {
    let { content: result } = await ReportService.instance.queryAnalyze(reportId);
    this.analysisResults = result.data.target && result.data.target.reportAnalyzeTargetList;
    this.analysisNumber = result.data.target && result.data.target.quantity;
    this.keyIndicatorsList = result.data.generalInspection && result.data.generalInspection.generalInspectionList;
    this.keyIndicatorsNumber = result.data.generalInspection && result.data.generalInspection.quantity;
  }

  // 获取客户详情
  protected async physicalReportDetail(reportId) {
    let { content: result } = await ReportService.instance.physicalReportDetail(reportId);
    this.ReportDetail = result.data;
  }

  /**
   * 跳转到小程序
   */
  protected onAgree(): any {
    this.onSave(null);
    this.showOverlay = false;
  }

  /**
   * 温馨提示弹框
   * @private
   * @returns boolean
   */
  private showOverlay: boolean = false;

  /**
   * 是否显示PDF
   * @private
   * @returns any
   */
  private showPDF: boolean = false;

  /**
   * 数据模型
   * @private
   * @returns any
   */
  private pdfURL: string = "";

  /**
   * 是否显示弹框
   * @private
   * @returns any
   */
  private showPDFDownloadModel: boolean = false;

  /**
   * 数据模型
   * @private
   * @returns any
   */
  private detailInfo: any = {
    attachments: [],
    employerMemberId: null,
    id: null,
    isAnalyze: null,
    isRead: false,
    memberId: null,
    memberName: "",
    orderId: null,
    orderNo: "",
    orgId: null,
    orgName: "",
    packageId: null,
    packageName: "",
    physicalDate: "",
    reportFileType: 1,
    reportFileTypeStr: "",
    reportKind: null,
    reportKindStr: "",
    reportName: "",
    canProceedUpload: true,
  };

  /**
   * 上传文件列表
   * @private
   * @returns boolean
   */
  private fileList: Array<any> = [];

  /**
   * 文件id集合
   * @private
   * @returns boolean
   */
  private fileIds: Array<any> = [];

  /**
   * 文件超出大小
   * @private
   * @returns void
   */
  private onOversize(file: any): void {
    Toast("超出限制大小,请重新调整后上传");
  }

  /**
   * 关闭PDF
   * @private
   * @returns void
   */
  private onClose() {
    this.showPDF = false;
  }

  /**
   * 文件读取完成后的回调函数
   * @private
   * @returns void
   */
  private onAfterRead(file: any): void {
    this.uploadFile(file.file);
  }

  /**
   * 文件上传
   * @private
   * @returns void
   */
  private async uploadFile(file: any): Promise<void> {
    try {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("files", file);
      formData.append("location", "2");
      let { content: res } = await FileService.instance.uploadFile(formData);
      if (res.data && res.data.length) {
        this.fileIds.push(res.data[0].id);
      }
    } catch (err) {
      Toast(err);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * 显示PDF
   * @private
   * @returns void
   */
  private async onShowFile(item: any): Promise<void> {
    this.pdfURL = item.url;
    this.showPDF = true;
  }

  /**
   * 文件上传
   * @private
   * @returns void
   */
  private async onDownloadFile(file: any): Promise<void> {
    try {
      let { content: res } = await ReportService.instance.downloadReport(this.$route.query.id || 355715359035461);
      this.pdfURL = res.data;
      // 如果是微信小程序嵌套h5
      if (await tools.isWeatchMini()) {
        this.showPDFDownloadModel = true;
        return;
      }
      if (tools.isAndroid()) {
        downloadFile(res.data);
      } else {
        this.showPDFDownloadModel = true;
      }
    } catch (err) {
      Toast(err);
    }
  }

  private async copyPdfUrl(str): Promise<void> {
    let save = function (e) {
      e.clipboardData.setData("text/plain", str);
      e.preventDefault();
    };
    let input = document.createElement("input");
    input.value = str;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, input.value.length), document.execCommand("Copy");
    document.body.removeChild(input);
    Toast({
      message: "复制成功",
      duration: 1000,
    });
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  protected async created() {
    if (!(await tools.isWeatchMini())) {
      this.isWeatchMini = false;
    }
    if (this.$route.query.id) {
      this.getPhysicalReportDetail(this.$route.query.id);
      this.queryAnalyze(this.$route.query.id);
    }
  }

  protected isWeatchMini = true;

  /**
   * 获取体检报告详情
   * @private
   * @returns void
   */
  private async getPhysicalReportDetail(id: any): Promise<void> {
    try {
      let { content: result } = await ReportService.instance.physicalReportDetail(id);
      if (result.data) {
        this.detailInfo = result.data;
        this.fileList = result.data.attachments || [];
        this.fileIds = result.data.attachments.map((item) => item.id);
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 删除文件
   * @private
   * @returns void
   */
  private async onDeleteFile(file: any, currentFile): Promise<void> {
    this.fileIds.splice(currentFile.index, 1);
  }

  /**
   * 跳转小程序封装
   * @protected
   * @returns void
   */
  private async wx_launch(info): Promise<void> {
    if (!(await tools.isWechat())) {
      return;
    }
    var btn = document.getElementById(info.eleId); //获取元素
    if (!btn) {
      return;
    }
    let script = document.createElement("script"); // 创建script内容插槽 避免template标签冲突
    script.type = "text/wxtag-template"; // 使用script插槽 必须定义这个type
    script.text = info.content; // 自定义的html字符串内容
    let html = `<wx-open-launch-weapp style="width:100%; height:100%;display:block;overflow: hidden;" username="${info.appid}" path="${info.url}">${script.outerHTML}</wx-open-launch-weapp>`;
    btn.innerHTML = html; // html字符串赋值
    // 点击按钮 正常跳转触发
    btn.addEventListener("launch", function (e) {
      console.log("success");
    });
    // 点击跳转 抛出异常
    btn.addEventListener("error", function (e) {
      console.log("fail", e);
      alert(`跳转异常 - ${JSON.stringify(e)}`);
    });
  }

  /**
   * 初始化jssdk
   * @private
   * @returns Promise<any>
   */
  private async init(): Promise<any> {
    if (await tools.isWechat()) {
      let url = location.href;
      let { content: result } = await WechatService.instance.getWechatJSSDK(url);
      if (result.data) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.data.appId, // 必填，公众号的唯一标识
          timestamp: result.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
          signature: result.data.signature, // 必填，签名
          jsApiList: ["openLocation"],
          openTagList: ["wx-open-launch-weapp"], // 必填，需要使用的JS接口列表
        });

        wx.ready(function () {});
      }
    }
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    let userInfoStorge = localStorage.getItem("userInfo");

    if (userInfoStorge) {
      return JSON.parse(userInfoStorge);
    }

    return {};
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 跳转个人版，获取Token的Key
   * @private
   * @returns void
   */
  private async setPersonalBtn(reportContent): Promise<void> {
    try {
      let { content: result } = await UserService.instance.jump2Personal();

      if (result.data) {
        // 跳转小程序
        let reportParams = {
          appid: "gh_387fc8fa08dd",
          eleId: "report", // 元素id
          url: `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/report/decode?id=${this.$route.query.id}`, // 跳转小程序的页面路径
          content: reportContent, // 自定义的html内容
        };

        this.wx_launch(reportParams);

        // 取消预约按钮
        let cancelParams = {
          appid: "gh_387fc8fa08dd",
          eleId: "appointment", // 元素id
          url: `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/report/decode?id=${this.$route.query.id}`, // 跳转小程序的页面路径
          content: reportContent, // 自定义的html内容
        };

        this.wx_launch(cancelParams);

        // 取消预约按钮
        let cancelParam = {
          appid: "gh_387fc8fa08dd",
          eleId: "cancelation", // 元素id
          url: `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/report/decode?id=${this.$route.query.id}`, // 跳转小程序的页面路径
          content: reportContent, // 自定义的html内容
        };
        this.wx_launch(cancelParam);
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * dom加载完
   * @protected
   * @returns void
   */
  protected async mounted() {
    this.init();
    if (await tools.isWechat) {
      let reportContent = `
                <button class="test-btn"></button>
                <style>
                    .test-btn{
                        width: 100%;
                        height: 520px;
                        background: transparent;
                        border: none;
                        color:rgba(255,255,255,0)
                    }
                    .test-btn:focus{outline: 0}
                </style>
                `;
      this.setPersonalBtn(reportContent);
    }
  }

  /**
   * 保存
   * @private
   * @returns void
   */
  private async onSave(img): Promise<void> {
    let data = {
      id: this.detailInfo.id,
      physicalDate: this.detailInfo.physicalDate,
      attachment: this.fileIds + "",
    };

    try {
      let { content: result } = await ReportService.instance.proceedUploadReport(data);

      if (result.data) {
        this.detailInfo = result.data;
        this.fileList = result.data.attachments || [];
        this.fileIds = result.data.attachments.map((item) => item.id);
      }
      // 保存成功跳转到小程序
      if (img) {
        setTimeout(() => {
          Toast("图片保存成功!");
          this.$router.go(-1);
        }, 500);
      }
    } catch (err) {
      Toast(err);
    }
  }

  // 取消预约解读
  private async cancelReservation() {
    try {
      await ReportService.instance.cancelReservation(this.detailInfo.id);
      Toast("已取消预约");
      setTimeout(() => {
        this.$router.go(0);
      }, 200);
    } catch (err) {
      Toast(err);
    }
  }
}
