
import { Component, Vue } from "vue-property-decorator";
import { Loading, Toast } from "vant";
import { ReportService, FileService } from "src/services";
import dayjs, { unix } from "dayjs";
import pageBack from "src/components/page-back";
import router from "src/router";
import globalConfig from "src/config/index";

@Component({
  components: {
    pageBack,
  },
})
export default class ReportLogin extends Vue {
  /**
   * 未授权报告
   * @private
   * @returns void
   */
  public physicalRes = [];

  /**
   * 报告id
   * @private
   * @returns void
   */
  /**
   * appId
   * @private
   * @returns string
   */
  private get id(): string {
    return (this.$route.query.id && (this.$route.query.id as string)) || "";
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  protected created() {
    let reportData = JSON.parse(localStorage.getItem("reportData"));
    this.physicalRes = reportData;
  }

  /**
   * 添加到我的档案
   * @private
   * @returns void
   */
  protected async onSubmit() {
    let reportData = this.physicalRes.filter((item) => item.isChecked);
    let reportIds = reportData.map((data) => data.id);
    try {
      await ReportService.instance.authReport({ reportIds });
      Toast("保存成功");
      if (this.id) {
        router.replace({ name: "reportAppointmentDetail", query: { id: this.id + "" } });
      } else {
        router.replace({ name: "reportAppointmentList" });
      }
    } catch (error) {}
  }
}
