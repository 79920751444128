
import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";
import dayjs from "dayjs";
import pageBack from "src/components/page-back";
import { ImagePreview, Dialog } from "vant";
import { employerReimbursementService } from "src/services";
import globalConfig from "src/config/index";

@Component({
  components: {
    pageBack,
  },
})
export default class ReportDecodeList extends Vue {
  /**
   * 取消报销申请弹窗
   */
  protected showOverlay: boolean = false;

  /**
   * 撤销id
   */
  protected revokeId: any = null;

  /**
   * 获取订单列表
   */
  protected async loadData(parameter: any): Promise<any> {
    try {
      let { content: res } = await employerReimbursementService.instance.queryReimbursement(Object.assign({}, parameter));
      return res.data;
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    let userInfoStorge = localStorage.getItem("reportUser") || localStorage.getItem("userInfo");

    if (userInfoStorge) {
      return JSON.parse(userInfoStorge);
    }

    return {};
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private mounted(): void {
    (this.$refs.myUPageList as any).onRefresh();
  }

  /**
   * 跳转页面
   * @private
   * @returns void
   */
  private onGopage(name: string): void {
    this.$router.push({ name });
  }

  /**
   * 取消报销申请
   * @private
   * @returns void
   */
  private onCancel(id: any): void {
    this.revokeId = id;
    this.showOverlay = true;
  }

  private async revokApplication() {
    try {
      await employerReimbursementService.instance.revokeReimbursement(this.revokeId);
      Toast("取消成功！");
      this.showOverlay = false;
      (this.$refs.myUPageList as any).onRefresh();
    } catch (error) {}
  }

  /**
   * 图片预览
   * @private
   * @returns void
   */
  private onPrevImg(data: Array<any>, index): void {
    let imgs = [];
    data.forEach((item) => {
      imgs.push(item);
    });
    ImagePreview({
      images: imgs,
      startPosition: index,
    });
  }
}
