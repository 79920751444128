
import { Component, Vue } from "vue-property-decorator";
import { Loading, Toast } from "vant";
import { ReportService, FileService } from "src/services";
import dayjs, { unix } from "dayjs";
import pageBack from "src/components/page-back";
import familylist from "src/components/family-list/family-list.vue";
import router from "src/router";
import globalConfig from "src/config/index";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component({
  components: {
    pageBack,
    familylist,
  },
})
export default class ReportDecodeList extends Vue {
  /**
   * 校验为必填项
   * @private
   *
   */
  private rulesForm = {
    familyUserName: { required: "请输入姓名", error: "" },
    brand: { required: "请输入手机号", error: "" },
    physicalDate: { required: "请选择证件类型", error: "" },
    upload: { required: "请上传pdf文件或图片", error: "" },
  };

  /**
   * 组件进来之前
   * @private
   * @returns void
   */
  private async beforeRouteEnter(to, from, next): Promise<void> {
    localStorage.setItem("evaquestion", from.name);
    next();
  }

  /**
   * 关闭弹窗
   * @private
   *
   */
  private close(date: any): void {
    this.showUserPopup = false;
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 手动添加品牌名称
   * @private
   *
   */
  private brandName: String = "";

  /**
   * 是否显示加载中
   * @private
   *
   */
  private isLoading: Boolean = false;

  /**
   * 添加品牌
   * @private
   *
   */
  private async addBrand(date: any) {
    await ReportService.instance.addbrandLog({
      memberId: this.formData.employerMemberId,
      brandName: this.brandName,
    });

    // this.getBrandList(this.formData.employerMemberId)
    this.brand = this.brandName;
    this.formData.OrgName = this.brandName;
    this.formData.supplierBrandId = 9999;
    this.showBrand = false;
  }

  /**
   * 品牌更改
   * @private
   *
   */
  private brandOnChange(value: any): void {}

  /**
   * 品牌
   * @private
   * @returns any
   */
  private brand: String = "";

  private analysis: Boolean = false;

  /**
   * 品牌确定
   * @private
   *
   */
  private brandOnConfirm(value: any): void {
    let res = this.brandListColumn.filter((item) => {
      return item.name == value;
    });

    this.formData.supplierBrandId = res[0].id;
    this.formData.OrgName = value;
    this.brand = value;
    this.checkHistoryForm();
    this.showBrand = false;
  }

  /**
   * 品牌取消
   * @private
   *
   */
  private onCancel(date: any): void {
    this.showBrand = false;
  }

  /**
   * 确定选中的体检人
   * @private
   * @returns void
   */
  private onConfirmUser(data: any): void {
    this.formData.employerMemberId = data.employerMemberId;
    this.familyUserName = data.memberName;
    this.checkHistoryForm();
    this.showUserPopup = false;
  }

  /**
   * 选择人员
   * @private
   * @returns any
   */
  private familyUserName: String = "";

  /**
   * 选择人员
   * @private
   * @returns any
   */
  private showUserPopup: boolean = false;

  /**
   * 体检日期
   * @private
   * @returns any
   */
  private date: any = dayjs().toDate();

  /**
   * 文件id集合
   * @private
   * @returns boolean
   */
  private fileIds: Array<any> = [];

  /**
   * 数据模型
   * @private
   * @returns any
   */
  private formData: any = {
    OrgName: "",
    physicalDate: "", // 体检日期
    mode: "",
    supplierBrandId: null, // 品牌id
    employerMemberId: 344055590805573, // 受检人id
    attachment: [], // 附件id集合(关联附件表)
    reportFileType: "1", // 1:PDF, 2: 图片
    file: "", // pdf文件
  };

  /**
   * pdf数据
   * @private
   * @returns any
   */
  private pdfInfo: any = {};

  /**
   * 日期弹框
   * @private
   * @returns boolean
   */
  private showDate: boolean = false;

  /**
   * 品牌展示框
   * @private
   * @returns boolean
   */
  private showBrand: boolean = false;

  /**
   * 是否显示弹框
   * @private
   * @returns boolean
   */
  private showDialog: boolean = false;

  /**
   * 上传文件列表
   * @private
   * @returns boolean
   */
  private fileList: Array<any> = [];

  /**
   * 日期控件
   * @private
   * @returns Date
   */
  private minDate: Date = new Date(1900, 1, 1);

  /**
   * 最大时间
   * @private
   * @returns Date
   */
  private maxDate: Date = dayjs().toDate();

  /**
   * 用户名
   * @private
   * @returns string
   */
  private get userName(): string {
    if (this.$route.query && this.$route.query.name) {
      return this.$route.query.name + "";
    }

    return null;
  }

  /**
   * 文件类型变更
   * @private
   * @returns void
   */
  private onRadioChange(): void {
    this.fileList = [];
    this.formData.file = null;
    this.formData.attachment = [];
    this.pdfInfo = {};
  }

  /**
   * 删除文件
   * @private
   * @returns void
   */
  private async onDeleteFile(file: any, currentFile): Promise<void> {
    if (this.formData.reportFileType == 2) {
      this.formData.attachment.splice(currentFile.index, 1);
    }
  }

  /**
   * 文件上传
   * @private
   * @returns void
   */
  private async uploadFile(file: any): Promise<void> {
    try {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("files", file);
      formData.append("location", "2");
      let { content: res } = await FileService.instance.uploadFile(formData);
      if (res.data && res.data.length) {
        this.formData.attachment.push(res.data[0].id);
      }
    } catch (err) {
      Toast(err);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * 点击知道了
   * @private
   * @returns void
   */
  private onClickDialog(date: any): void {
    this.showDialog = false;
    this.$router.go(-1);
  }

  /**
   * 删除文件
   * @private
   * @returns void
   */
  private onDelFile(): void {
    this.pdfInfo = {};
    this.fileList = [];
  }

  /**
   * 文件超出大小
   * @private
   * @returns void
   */
  private onOversize(file: any): void {
    if (this.formData.reportFileType == "1") {
      Toast("PDF超出限制大小,请重新调整后上传");
    } else {
      Toast("图片超出限制大小,请重新调整后上传");
    }
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created() {
    if (this.$route.query.id) {
      this.formData.employerMemberId = this.$route.query.id;
      this.getBrandList();
    }

    if (this.$route.query && this.$route.query.name) {
      this.familyUserName = this.$route.query.name + "";
    }
  }

  /**
   * 上传文件列表
   * @private
   * @returns boolean
   */
  private brandList: Array<any> = [];
  private brandListColumn: Array<any> = [];

  /**
   * 获取品牌列表
   * @private
   * @returns void
   */
  protected async getBrandList() {
    let { content: result } = await ReportService.instance.getSupplierBrandList();
    this.brandList = result.data.map((item) => item.name);
    this.brandListColumn = result.data;
  }

  /**
   * 文件读取完成后的回调函数
   * @private
   * @returns void
   */
  private onAfterRead(file: any): void {
    if (this.formData.reportFileType == 1) {
      this.pdfInfo = file;
      this.formData.file = file.file;
    } else {
      this.uploadFile(file.file);
    }
    this.checkHistoryForm();
  }

  //表单校验
  private checkHistoryForm() {
    let pd = true;
    if (!this.familyUserName.trim()) {
      this.rulesForm.familyUserName.error = "请输入姓名";
      pd = false;
    } else {
      this.rulesForm.familyUserName.error = "";
    }

    if (!this.brand.trim()) {
      this.rulesForm.brand.error = "请选择品牌";
      pd = false;
    } else {
      this.rulesForm.brand.error = "";
    }

    if (!this.formData.physicalDate) {
      this.rulesForm.physicalDate.error = "请选择日期";
      pd = false;
    } else {
      this.rulesForm.physicalDate.error = "";
    }

    if (!this.fileList.length && !this.pdfInfo.file) {
      this.rulesForm.upload.error = "请上传文件或图片";
      pd = false;
    } else {
      this.rulesForm.upload.error = "";
    }

    return pd;
  }

  /**
   * 保存
   * @private
   * @returns void
   */
  private async onSave(): Promise<void> {
    let evaquestion = localStorage.getItem("evaquestion");

    if (!this.checkHistoryForm()) {
      return;
    }

    this.checkHistoryForm();

    let formData = new FormData();
    Object.keys(this.formData).forEach((item) => {
      formData.append(item, this.formData[item]);
    });

    try {
      let { content: res } = await ReportService.instance.uploadReport(formData);
      Toast("上传成功");
      if (this.formData.reportFileType == "1") {
        // 判断上传报告页与列表页
        if (evaquestion == "replacementReport") {
          this.$router.replace({ name: "replacementReport", query: { id: this.formData.employerMemberId } });
          this.$router.go(-1);
        } else {
          const query = { reportId: res.data, reportFileType: "1", supplierBrandId: this.formData.supplierBrandId };
          let currentRouterName = "reportAppointmentUpload";
          this.$store.dispatch("setLastRouter", currentRouterName);
          this.$store.dispatch("uploadParams", query);
          // this.$router.replace({name: "reportAppointmentList",query})
          this.$router.go(-1);
        }
      } else {
        // 判断上传报告页与列表页
        if (evaquestion == "replacementReport") {
          this.$router.replace({ name: "replacementReport", query: { id: this.formData.employerMemberId } });
          this.$router.go(-1);
        } else {
          this.$router.go(-1);
        }
      }
    } catch (err) {
      Toast(err);
      this.analysis = false;
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * 确定日期选择
   * @private
   * @returns void
   */
  private onDateConfirm(date: any): void {
    if (date) {
      this.formData.physicalDate = dayjs(date).format("YYYY/MM/DD");
    }

    this.checkHistoryForm();

    this.showDate = false;
  }

  /**
   * 取消日期选择
   * @private
   * @returns void
   */
  private onDateCancel(): void {
    this.showDate = false;
  }
}
