
import { Component, Vue } from "vue-property-decorator";
import { Dialog, Toast } from "vant";
import dayjs from "dayjs";
import { employerReimbursementService } from "src/services";
import BankService from "src/services/bank-service";
import globalConfig from "src/config/index";

@Component
export default class UserSetting extends Vue {
  /**
   * 关键词
   */
  private key: string = "";

  /**
   * 返回
   */
  private customFn() {
    this.$emit("close-banklist");
  }

  /**
   * 获取收款银行列表
   */
  protected async loadData(parameter: any): Promise<any> {
    try {
      let { content: res } = await BankService.instance.getBankList(Object.assign({}, parameter, { key: this.key }));
      return res.data;
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 搜索
   */
  private onSearch() {
    (this.$refs.myUPageList as any).onRefresh();
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 选择收款银行
   */
  private selectBank(item) {
    // this.$router.go(-1)
    // localStorage.setItem('bankName',item.value)
    this.$emit("close-banklist", item.value);
  }
}
