
import { Component, Vue } from "vue-property-decorator";
import { Loading, Toast } from "vant";
import { ReportService, FileService, UserService, FamilyService } from "src/services";
import dayjs, { unix } from "dayjs";
import pageBack from "src/components/page-back";
import router from "src/router";
import globalConfig from "src/config/index";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component({
  components: {
    pageBack,
  },
})
export default class ReportDecode extends Vue {
  /**
   * 登录数据模型
   * @private
   * @returns any
   */
  public modelData: any = {
    phone: "",
    code: "",
  };

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    return this.$store.getters.userInfo || {};
  }

  /**
   * 点击获取验证码等60秒完成后才可以重新点击发送
   * @private
   * @returns boolean
   */
  private isDisabled: boolean = false;

  /**
   * 获取验证码
   * @private
   * @returns string
   */
  private sendCode: string = "获取验证码";

  /**
   * 获取验证码60秒
   * @private
   * @returns string
   */
  private time: any = 60;

  /**
   * 上个页面路由名称
   * @private
   * @returns string
   */
  private beforeRouteName: string = "";

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 组件进来之前
   * @private
   * @returns void
   */
  private beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeRouteName = from.name;
    });
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created(): any {
    this.getUserDetail();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.modelData.phone = userInfo.phone;
  }

  /**
   * 获取用户详情
   * @private
   * @returns Promise<void>
   */
  private async getUserDetail(): Promise<void> {
    try {
      let { content: result } = await FamilyService.instance.getFamilyDetail(this.userInfo.userId);
      if (result.data) {
        this.modelData.phone = result.data.phone;
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 获取验证码
   * @private
   * @returns void
   */
  public async onGetModifyCode(): Promise<void> {
    try {
      if (!this.modelData.phone || this.modelData.phone.length != 11) {
        Toast({
          duration: 1000,
          message: "请输入11位手机号",
        });

        return;
      }

      await ReportService.instance.sendConfirm();
      Toast("验证码发送成功");
      this.isDisabled = true;
      let interval = window.setInterval(() => {
        this.sendCode = `${this.time}s`;
        --this.time;
        if (this.time < 0) {
          this.sendCode = "重新发送";
          this.time = 60;
          this.isDisabled = false;
          window.clearInterval(interval);
        }
      }, 1000);
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 来源页面
   * @private
   * @returns void
   */
  private get sourcePage(): any {
    return this.$route.query?.sourcePage || null;
  }

  /**
   * 提交验证
   * @private
   * @returns void
   */
  public async onSubmitVerify() {
    try {
      await ReportService.instance.confirmIdentity({ code: this.modelData.code });
      let { isAuth, id } = this.$route.query;
      // 首页或报告管理
      if (this.beforeRouteName == "home" || this.beforeRouteName == "personal-center") {
        this.$router.replace({ name: "reportAppointmentList" });
      }
      // 切换报告
      else if (this.beforeRouteName == "relatedReport") {
        this.$router.replace({ name: "reportReplacementReport", query: { id: id, sourcePage: this.sourcePage } });
      }
      // 报告列表页查询报告
      else if (this.beforeRouteName == "reportAppointmentList") {
        // 上传页面 ——> 报告列表
        let uploadRouterName = "reportAppointmentUpload";
        if (this.lastRouter === uploadRouterName) {
          this.$router.replace({ name: "reportAppointmentList" });
        }
        // 报告列表（查询） ——> 查询报告页面
        else {
          this.$router.replace({ name: "reportQuery" });
        }
      }
      // 预约解读页
      else if (this.beforeRouteName == "reportDecode") {
        this.$router.replace({ name: "reportReplacementReport" });
      }
      // 查询报告
      else {
        //  是否已授权
        if (isAuth) {
          this.$router.replace({ name: "reportAppointmentDetail", query: { id } });
        } else {
          this.$router.replace({ name: "reportQuery", query: { id } });
        }
      }
    } catch (error) {
      Toast(error);
    }
  }

  /**
   * vuex记录的前一个页面的路由
   * @private
   * @returns void
   */
  private get lastRouter(): any {
    return this.$store.getters.getLastRouter || null;
  }
}
