
import { Component, Vue, Prop } from "vue-property-decorator";
import { ReportService } from "src/services";

export default class ReportDecodeList extends Vue {
  /**
   * 获取的id
   * @private
   * @returns string
   */
  @Prop({ type: [Number, String], default: null })
  private id: any;

  /**
   * 报告id
   * @protected
   * @returns Array<any>
   */
  protected reportId = null;

  /**
   * 全部指标
   * @protected
   * @returns Array<any>
   */
  protected activeNames = ["1"];

  /**
   * 机构品牌
   * @protected
   * @returns Array<any>
   */
  protected ReportDetail = {};

  /**
   * 解析结果
   * @protected
   * @returns Array<any>
   */
  protected analysisResults = [];

  /**
   * 解析数量
   * @protected
   * @returns Array<any>
   */
  protected analysisNumber = null;

  /**
   * 跳转页面
   * @private
   * @returns void
   */
  private onGopage(name: string): void {
    this.$router.push({ name });
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  protected created(): void {
    this.reportId = this.id;
    //  this.queryAnalyze(this.reportId )
    //  this.physicalReportDetail(this.reportId)
  }

  //获取解析的体检报告
  protected async queryAnalyze(reportId) {
    let { content: result } = await ReportService.instance.queryAnalyze(reportId);
    this.analysisResults = result.data.reportAnalyze;
    this.analysisNumber = result.data.quantity;
  }

  // 获取客户详情
  protected async physicalReportDetail(reportId) {
    let { content: result } = await ReportService.instance.physicalReportDetail(reportId);
    this.ReportDetail = result.data;
  }
}
