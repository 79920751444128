
import { Component, Vue } from "vue-property-decorator";
import { ReportService, FileService, UserService, FamilyService } from "src/services";
import pageBack from "src/components/page-back";
import globalConfig from "src/config/index";
import router from "src/router";
import { Toast } from "vant";

@Component({
  components: {
    pageBack,
  },
})
export default class ReportReplacement extends Vue {
  // 本人体检报告数量
  private selfTotalReportNum: any = 0;

  // 家属体检报告数量
  private familyUnReadReportNum: any = 0;

  // 是否显示选择人员
  private showUserPopup: boolean = false;

  // 报告列表
  private dataList: Array<any> = [];

  /**
   * 获取传入的报告id
   * @private
   * @returns void
   */
  private get reportId(): any {
    return this.$route.query?.id || null;
  }

  /**
   * 获取传入之前选择人
   * @private
   * @returns void
   */
  private get employerMemberId(): any {
    return this.$route.query?.employerMemberId || null;
  }

  /**
   * 来源页面
   * @private
   * @returns void
   */
  private get sourcePage(): any {
    return this.$route.query?.sourcePage || null;
  }

  /**
   * 当前选中用户
   * @protected
   * @returns string
   */
  protected currentUser: any = {};

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 获取列表
   */
  protected async getList(): Promise<any> {
    try {
      let { content: result } = await ReportService.instance.getPhysicalReportList({
        employerMemberId: this.currentUser.employerMemberId,
      });
      if (result.data) {
        this.dataList = result.data.reportGroups;
        this.familyUnReadReportNum = result.data.familyUnReadReportNum;
        this.selfTotalReportNum = result.data.selfTotalReportNum;
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 确认切换用户
   * @private
   * @returns void
   */
  protected onConfirmUser(data: any): void {
    this.currentUser = {
      employerMemberId: data.employerMemberId,
      memberType: data.memberType,
      familyType: data.familyType,
      phone: data.phone,
      name: data.memberName,
      cardNo: data.cardNo,
      sex: data.sex,
      marriageStatus: data.marriageStatus,
      marriageStatusStr: data.marriageStatusStr,
      memberTypeStr: data.memberTypeStr,
      sexStr: data.sexStr,
      age: data.age,
      memberId: data.memberId,
    };
    this.showUserPopup = false;
    localStorage.setItem("selectUser", JSON.stringify(this.currentUser));
    this.getList();
  }

  // 跳转报告上传
  private onActionUpLoad() {
    router.push({
      name: "reportAppointmentUpload",
      query: { id: this.currentUser.employerMemberId, name: this.currentUser.name },
    });
  }

  /**
   * 获取用户列表
   * @protected
   * @returns Array<any>
   */
  protected userList: Array<any> = [];

  /**
   * 获取用户列表
   * @private
   * @returns void
   */
  protected getUserList(userList: any): void {
    this.userList = userList;
    let data = userList.find((res) => res.employerMemberId == this.employerMemberId) || userList[0];
    if (!this.currentUser.employerMemberId) {
      this.$nextTick(() => {
        this.getList();
      });
    }

    this.currentUser = {
      employerMemberId: data.employerMemberId,
      memberType: data.memberType,
      familyType: data.familyType,
      phone: data.phone,
      name: data.memberName,
      cardNo: data.cardNo,
      sex: data.sex,
      marriageStatus: data.marriageStatus,
      marriageStatusStr: data.marriageStatusStr,
      memberTypeStr: data.memberTypeStr,
      sexStr: data.sexStr,
      age: data.age,
      memberId: data.memberId,
    };
    this.getList();
  }

  // 跳转体检报告列表
  examinationReport(row: any) {
    if (this.sourcePage) {
      this.$router.replace({ name: this.sourcePage, query: { reportId: row.id, name: row.reportName } });
    } else {
      this.$router.replace({ name: "reportDecode", query: { reportId: row.id } });
    }
  }
}
