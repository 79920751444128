
import { Component, Vue } from "vue-property-decorator";
import { ReportService, UserService } from "src/services";
import { Toast } from "vant";
import { tools } from "src/utils";
import { ILogin } from "src/model";
import { DesSecretUtils } from "src/utils";
import router from "src/router";

@Component
export default class ReportLogin extends Vue {
  /**
   * 登录数据模型
   * @private
   * @returns any
   */
  private modelData: any = {
    reportId: 0,
    cardNo: "",
    phone: "",
    smsCode: "",
  };

  /**
   * 点击获取验证码等60秒完成后才可以重新点击发送
   * @private
   * @returns boolean
   */
  private isDisabled: boolean = false;

  /**
   * 获取验证码
   * @private
   * @returns string
   */
  private sendCode: string = "获取验证码";

  /**
   * 获取验证码60秒
   * @private
   * @returns string
   */
  private time: any = 60;

  /**
   * 获取当前登入机构信息
   * @private
   * @returns void
   */
  private get orgInfo(): any {
    return this.$store.getters.orgInfo || {};
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created(): any {
    if (this.$route.query.id) {
      this.modelData.reportId = this.$route.query.id;
    }
  }

  /**
   * 登录
   * @private
   * @returns void
   */
  private async onLogin(): Promise<void> {
    try {
      let { content: result } = await ReportService.instance.reportLogin(this.modelData);
      router.push({ name: "reportAppointmentDetail", query: { id: this.modelData.reportId + "" } });
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 获取验证码
   * @private
   * @returns void
   */
  private async onGetModifyCode(): Promise<void> {
    try {
      if (!this.modelData.cardNo) {
        Toast({
          duration: 1000,
          message: "请输入证件信息",
        });

        return;
      }

      if (!this.modelData.phone || this.modelData.phone.length != 11) {
        Toast({
          duration: 1000,
          message: "请输入11位手机号",
        });

        return;
      }

      let data = {
        reportId: this.modelData.reportId,
        smsType: 99, // 找回密码
        phone: this.modelData.phone,
      };

      await UserService.instance.smsSend(data);

      Toast("验证码发送成功");
      this.isDisabled = true;
      let interval = window.setInterval(() => {
        this.sendCode = `${this.time}s`;
        --this.time;
        if (this.time < 0) {
          this.sendCode = "重新发送";
          this.time = 60;
          this.isDisabled = false;
          window.clearInterval(interval);
        }
      }, 1000);
    } catch (err) {
      Toast(err);
    }
  }
}
